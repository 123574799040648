/*
 * 업무구분: 수익증권>공통>신분증진위확인
 * 화 면 명: MSPFS500P
 * 화면설명: 신분증진위확인 POPUP
 * 작 성 일: 2023.03.14
 * 작 성 자: 송진의
 */
<template>  
  <!-- popup -->
  <mo-modal class="fts-modal medium pop-beforeMain pop-idFakeCheck noClose" ref="idFakeCheck" title="신분증 진위 확인">
    <template>
      <div class="wrap-modalcontents">
        <mo-tab-box :defaultIdx="defaultIdxs" @tab-change="fn_TabChangeHandler" ref="tabbox">
          <!-- 주민등록증 -->          
          <mo-tab-content :idx="1" label="주민등록증">
            <div class="wrap-table mt16">
              <mo-validate-watcher ref="vWatcherKorean">
                <table class="table">
                  <tbody>
                    <tr>
                      <th>성명</th>
                      <td>
                        <div class="wrap-input row">
                          <mo-text-field v-model="lv_Korean.custNm" class="input-long" maxlength="6" placeholder="입력하세요" ref="koName" :rules="validateRuleCustNm" />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th>주민등록번호</th>
                      <td>
                        <div class="wrap-input row">                          
                          <mo-decimal-field v-model.trim="lv_Korean.knbFrno" class="input-long" numeric mask="######" placeholder="6자리" error-message=""  :rules="validateRuleKnbFrno" ref="koKnbFrno" @keyup="fn_NextFocus"/>
                          <m-trans-key-input v-if="fn_IsMobile" v-model.trim="lv_Korean.knbBkno"  dialog="Y" class="input-long" :key="lv_SelectedSeg" type="numberMax7" start="1" end="-1" @mask-type="fn_getMaskType" ref="koKnbBrno" @masked-txt="fn_SetMaskedTxt"  :rules="validateRuleKnbBrno" :isClear="lv_isKoClear"/>
                          <mo-decimal-field v-else v-model.trim="lv_Korean.knbBkno" class="input-long" password numeric mask="#######" placeholder="●●●●●●●" error-message=""  ref="koKnbBrno" :rules="validateRuleKnbBrno"/>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th>발급일자</th>
                      <td>
                        <div class="wrap-input row">
                          <mo-date-picker v-model.trim="lv_Korean.idcdIsuDt" class="input-long" init="year-20" :min-date="lv_MinDate" :max-date="lv_MaxDate" ref="koDate" :bottom="false"  :rules="validateRuleIdcdIsuDt"/>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </mo-validate-watcher>
            </div>
          </mo-tab-content>
          
          <mo-tab-content :idx="2" label="운전면허증">
            <div class="wrap-table mt16">
              <mo-validate-watcher ref="vWatcherDriver">
                <table class="table">
                  <tbody>
                    <tr>
                      <th>성명</th>
                      <td>
                        <div class="wrap-input row">
                          <mo-text-field v-model.trim="lv_Driver.custNm" class="input-long" maxlength="6" :rules="validateRuleCustNm" ref="drName" placeholder="입력하세요"/>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th>주민등록번호</th>
                      <td>
                        <div class="wrap-input row">
                          <mo-decimal-field v-model.trim="lv_Driver.knbFrno" class="input-long" numeric mask="######" placeholder="6자리" error-message="" :rules="validateRuleKnbFrno" ref="drKnbFrno" @keyup="fn_NextFocus" />
                          <m-trans-key-input v-if="fn_IsMobile" v-model.trim="lv_Driver.knbBkno" dialog="Y" :key="lv_SelectedSeg" type="numberMax7" start="1" end="-1" @mask-type="fn_getMaskType" @masked-txt="fn_SetMaskedTxt" ref="drKnbBrno" :rules="validateRuleKnbBrno" :isClear="lv_isDrClear"/>
                          <mo-decimal-field v-else v-model.trim="lv_Driver.knbBkno" password numeric mask="#######" placeholder="●●●●●●●" error-message="" :rules="validateRuleKnbBrno" ref="drKnbBrno" />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th>면허번호</th>
                      <td>
                        <div class="wrap-input row">
                          <mo-decimal-field ref="lcnsNo1" class="w60" v-model.trim="lv_Driver.lcnsNo1" numeric mask="##" placeholder="2자리" error-message="" :rules="validateRuleLcnsNo1" @keyup="fn_NextFocusLcnsNo(1)"/>
                          <mo-decimal-field ref="lcnsNo2" class="w60" v-model.trim="lv_Driver.lcnsNo2" numeric mask="##" placeholder="2자리" error-message="" :rules="validateRuleLcnsNo2" @keyup="fn_NextFocusLcnsNo(2)"/>
                          <mo-decimal-field ref="lcnsNo3" v-model.trim="lv_Driver.lcnsNo3" numeric mask="######" placeholder="6자리" error-message="" :rules="validateRuleLcnsNo3" @keyup="fn_NextFocusLcnsNo(3)"/>
                          <mo-decimal-field ref="lcnsNo4" class="w60" v-model.trim="lv_Driver.lcnsNo4" numeric mask="##" placeholder="2자리" error-message="" :rules="validateRuleLcnsNo4"/>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </mo-validate-watcher>
            </div>
            <div class="wrap-desc">
              <h2>운전면허 지역번호 안내</h2>
              <span>
                서울(11) 부산(12), 경기남부(13), 강원(14), 충북(15),충남(16), 전북(17), 전남(18), 경북(19), 경남(20)
                제주(21), 대구(22), 인천(23), 광주(24), 대전(25), 울산(26), 경기북부(28)
              </span>
            </div>
          </mo-tab-content>
          
          <mo-tab-content :idx="3" label="외국인등록증">
            <div class="wrap-table mt16">
              <mo-validate-watcher ref="vWatcherForeigner">
                <table class="table">
                  <tbody>
                    <tr>
                      <th>성명</th>
                      <td>
                        <div class="wrap-input row">
                          <mo-text-field ref="foName" v-model.trim="lv_Foreigner.custNm" class="input-long" maxlength="30" placeholder="입력하세요" :rules="validateRuleCustNm"/>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th>외국인등록번호<br/>(거소신고번호)</th>
                      <td>
                        <div class="wrap-input row">
                          <mo-decimal-field v-model.trim="lv_Foreigner.knbFrno" class="input-long" numeric mask="######" placeholder="6자리" error-message="" :rules="validateRuleKnbFrno" ref="foKnbFrno" @keyup="fn_NextFocus"/>
                          <m-trans-key-input v-if="fn_IsMobile" v-model.trim="lv_Foreigner.knbBkno" dialog="Y" class="input-long" :key="lv_SelectedSeg" type="numberMax7" start="1" end="-1" @mask-type="fn_getMaskType" ref="foKnbBrno" @masked-txt="fn_SetMaskedTxt" :rules="validateRuleKnbBrno" :isClear="lv_isFoClear"/>
                          <mo-decimal-field v-else v-model.trim="lv_Foreigner.knbBkno" class="input-long" password numeric mask="#######" placeholder="●●●●●●●" error-message="" ref="foKnbBrno" :rules="validateRuleKnbBrno" />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th>발급일자</th>
                      <td>
                        <div class="wrap-input row">
                          <mo-date-picker ref="foDate" v-model.trim="lv_Foreigner.idcdIsuDt" class="input-long" init="year-20" :min-date="lv_MinDate" :max-date="lv_MaxDate" :bottom="false" :rules="validateRuleIdcdIsuDt" />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th>일련번호</th>
                      <td>
                        <div class="wrap-input row">
                          <mo-decimal-field ref="seriaNoRef" v-model.trim="lv_Foreigner.seriaNo" class="input-long" numeric placeholder="일련번호(11자리)" mask="###########" error-message="" />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </mo-validate-watcher>
            </div>
            <div class="wrap-desc">
              <!-- <h2> </h2> -->
              <span>
                ※ 2011.1.1 이전에 발급된 등록증은 일련번호 입력할 필요 없음(일련번호 없는 경우)
              </span>
            </div>
          </mo-tab-content>
        </mo-tab-box>
      </div>
    </template>
    <template slot="action">
      <div class="wrap-button button-page-bottom row">
        <mo-button point size="large" @click="fn_ExitModal">닫기</mo-button>
        <mo-button size="large" primary v-if="lv_isTestBtn" @click="fn_IdFakeCheckSucs(true)">확인(T)-서류Skip</mo-button> 
        <mo-button size="large" primary v-if="lv_isTestBtn" @click="fn_IdFakeCheckSucs()">확인(T)</mo-button>
        <mo-button size="large" primary @click="fn_CheckSsnValidation" :disabled="fn_IsDisabledConfirm">확인</mo-button>
      </div>
    </template>
    <fs-alert-popup 
      ref="alertPopup" 
      :popupObj="pAlertPopupObj"
    >
    </fs-alert-popup>
    <!-- 완료 시 -->
    <mo-modal class="fts-modal small" ref="authSucs">
      <template>
        <span class="wrap-text">
          신분증 진위가<br/>
          확인되었습니다.<br/>          
        </span>
      </template>
      <template slot="action">
        <div class="wrap-button button-page-bottom row">          
          <mo-button primary size="large" @click="fn_AuthSucs">확인</mo-button>
        </div>
      </template>
    </mo-modal>
    <!-- 닫기 시 -->
    <mo-modal class="fts-modal small noClose" ref="exit">
      <template>
        <span class="wrap-text">
          신분증 진위 확인을 닫으면<br/>
          업무 진행이 중단되고 앱이 종료됩니다.<br/>
          중단하시겠습니까?<br/>          
        </span>
      </template>
      <template slot="action">
        <div class="wrap-button button-page-bottom row">
          <mo-button point size="large" @click="closeModal('Exit')">취소</mo-button>
          <mo-button primary size="large" @click="fn_ExitConfirmYes">확인</mo-button>
        </div>
      </template>
    </mo-modal>
    <!-- 인증번호 다건 인증 팝업 -->
    <mo-modal class="fts-modal small noClose" ref="massAuth">
      <template>
        <span class="wrap-text">
          신분증 진위가<br/>
          확인되었습니다.<br/>
          추가로 신분증 진위확인을<br/>
          진행하시겠습니까?<br/>
        </span>
      </template>
      <template slot="action">
        <div class="wrap-button button-page-bottom row">
          <mo-button point size="large" @click="fn_MassAuth('N')">아니오</mo-button>
          <mo-button primary size="large" @click="fn_MassAuth('Y')">예</mo-button>
        </div>
      </template>
    </mo-modal>
  </mo-modal>
</template>
<script>
/***********************************************************************************
 * 공통 라이브러리 INCLUDE 영역	                                                   *
 ***********************************************************************************/
import moment from 'moment'
import FSAlertPopup from '@/ui/fs/comm/FSAlertPopup'

export default {
  /***********************************************************************************
   * Vue 파일 속성 정의 영역	                                                       *
   ***********************************************************************************/
  name: "MSPFS500P",
  screenId: "MSPFS500P",
  components: {
    'fs-alert-popup': FSAlertPopup,
  },
  // bottom-sheet Full Popup
  modalProps: {
    full: true,
    mode: 'right' // 우->좌 open popup (defalut: 하->상)
  },
  props: {
    tabIdx: {
      type: [String, Number],
      default: 1
    },
    popupObj: {
      type: [Object],
      default: null
    }
  },
  /***********************************************************************************
   * Life Cycle 함수 정의 영역	                                                     *
   ***********************************************************************************/
  created() {
    let tabIndex = this.defaultIdxs
    this.fn_TabChangeHandler(tabIndex)
    if (process.env.NODE_ENV === 'production') {
      this.lv_isTestBtn = false
    }
  },
  mounted() {
    this.$bizUtil.insSrnLog("MSPFS500P");
  },
  /***********************************************************************************
   * 화면변수 선언 영역	                                                             *
   ***********************************************************************************/
  data() {    
    return {
      tlgmNo: '999999',
      cntU149: 0,
      scrapYn: '',
      lv_SelectedSeg: this.tabIdx == 1 ? 'lv_Korean'
                    : this.tabIdx == 2 ? 'lv_Driver'
                    : this.tabIdx == 3 ? 'lv_Foreigner'
                    : 'lv_Korean',
      lv_MaxDate: moment().add(0, 'year').format('YYYY-MM-DD'),
      lv_MinDate: moment().add(-120, 'year').format('YYYY-MM-DD'),
      // 주민등록증
      lv_Korean: {
        custNm: '', // 성명
        knbFrno: '', // 식별번호 앞자리
        knbBkno: '', // 식별번호 뒷자리
        idcdIsuDt: '', // 발급일자 (YYYY-MM-DD)
        knbBknoTxt: '', // 식별번호 뒷자리(masked)
        knbScCd: '', // ios encrypt key
        knbEncr: '', // 식별번호 (encrypt)
      },
      // 운전면허증
      lv_Driver: {
        custNm: '', // 성명
        knbFrno: '', // 식별번호 앞자리
        knbBkno: '', // 식별번호 뒷자리
        // lcnsNo: '', // 면허번호
        lcnsNo1: '', // 면허번호1
        lcnsNo2: '', // 면허번호2
        lcnsNo3: '', // 면허번호3
        lcnsNo4: '', // 면허번호4
        knbBknoTxt: '', // 식별번호 뒷자리(masked)
        knbScCd: '', // ios encrypt key
        knbEncr: '', // 식별번호 (encrypt)
      },
      // 외국인등록증
      lv_Foreigner: {
        custNm: '', // 성명
        knbFrno: '', // 식별번호 앞자리
        knbBkno: '', // 식별번호 뒷자리
        idcdIsuDt: '', // 발급일자 (YYYY-MM-DD)
        knbBknoTxt: '', // 식별번호 뒷자리(masked)
        knbScCd: '', // ios encrypt key
        knbEncr: '', // 식별번호 (encrypt)
        seriaNo: '', // 일련번호 11자리
      },      
      defaultIdxs : 1,
      validateRuleCustNm: [
        v => !!v || '필수입력 항목입니다',
      ],
      validateRuleKnbFrno: [
        v => !!v || '필수입력 항목입니다',
        v => v.length === 6 || '주민등록번호 앞 6자리를 입력하세요'
      ],
      validateRuleKnbBrno: [
        v => !!v || '필수입력 항목입니다',
        v => v.length === 7 || '주민등록번호 뒤 7자리를 입력하세요'
      ],
      validateRuleIdcdIsuDt: [
        v => !!v || '필수입력 항목입니다',
      ],
      validateRuleLcnsNo1: [
        v => !!v || '필수입력 항목입니다',
        v => v.length === 2 || '2자리를 입력하세요',
      ],
      validateRuleLcnsNo2: [
        v => !!v || '필수입력 항목입니다',
        v => v.length === 2 || '2자리를 입력하세요',
      ],
      validateRuleLcnsNo3: [
        v => !!v || '필수입력 항목입니다',
        v => v.length === 6 || '6자리를 입력하세요',
      ],
      validateRuleLcnsNo4: [
        v => !!v || '필수입력 항목입니다',
        v => v.length === 2 || '2자리를 입력하세요',
      ],
      isConfirm: false,
      pAlertPopupObj: {},
      lv_IsSkip: false,
      lv_isKoClear: false,
      lv_isDrClear: false,
      lv_isFoClear: false,
      lv_isTestBtn: true,
    };
  },
  /***********************************************************************************
   * Computed 함수 정의 영역                                                         *
   ***********************************************************************************/
  computed: {
    // modal
    modal1() {return this.$refs.idFakeCheck},
    modalExit() {return this.$refs.exit},
    modalMassAuth() {return this.$refs.massAuth},
    modalAuthSucs() {return this.$refs.authSucs},
    fn_IsMobile() {
      return this.getStore('deviceState').getters.getIsMobile
    },
    fn_IsDisabledConfirm() {
      const target = this[this.lv_SelectedSeg]
      
      if (this.lv_SelectedSeg === 'lv_Korean') {
        return !(target.custNm.length > 1
          && target.knbFrno.length === 6
          && (this.fn_IsMobile ? target.knbBknoTxt.length === 7 : target.knbBkno.length === 7)
          && target.idcdIsuDt.length === 10
          )
      } else if (this.lv_SelectedSeg === 'lv_Driver') {
        return !(target.custNm.length > 1
          && target.knbFrno.length === 6
          && (this.fn_IsMobile ? target.knbBknoTxt.length === 7 : target.knbBkno.length === 7)
          && (target.lcnsNo1.length === 2 && target.lcnsNo2.length === 2 && target.lcnsNo3.length === 6 && target.lcnsNo4.length === 2))
      } else if (this.lv_SelectedSeg === 'lv_Foreigner') {
        return !(target.custNm.length > 1
          && target.knbFrno.length === 6
          && (this.fn_IsMobile ? target.knbBknoTxt.length === 7 : target.knbBkno.length === 7)
          && target.idcdIsuDt.length === 10
          && ((target.idcdIsuDt >= '2011-01-01' && target.seriaNo.length === 11) || (target.idcdIsuDt < '2011-01-01'))
          )
      }

      return false
    },    
  },
  /***********************************************************************************
   * watch 함수 정의 영역                                                         *
   ***********************************************************************************/
  watch: {
    lv_SelectedSeg(val) {
      const target = this[val]
      target.knbBkno = ''
      target.knbBknoTxt = ''
      target.knbScCd = ''
      target.knbEncr = ''
    }
  },
  /***********************************************************************************
   * 사용자 함수 정의 영역                                                           *
   ***********************************************************************************/
  methods: {
    fn_Init() {
      const tmpKorean = {
        custNm: '', // 성명
        knbFrno: '', // 식별번호 앞자리
        knbBkno: '', // 식별번호 뒷자리
        idcdIsuDt: '', // 발급일자 (YYYY-MM-DD)
        knbBknoTxt: '', // 식별번호 뒷자리(masked)
        knbScCd: '', // ios encrypt key
        knbEncr: '', // 식별번호 (encrypt)
      }
      // 운전면허증
      const tmpDriver = {
        custNm: '', // 성명
        knbFrno: '', // 식별번호 앞자리
        knbBkno: '', // 식별번호 뒷자리
        // lcnsNo: '', // 면허번호
        lcnsNo1: '', // 면허번호1
        lcnsNo2: '', // 면허번호2
        lcnsNo3: '', // 면허번호3
        lcnsNo4: '', // 면허번호4
        knbBknoTxt: '', // 식별번호 뒷자리(masked)
        knbScCd: '', // ios encrypt key
        knbEncr: '', // 식별번호 (encrypt)
      }
      // 외국인등록증
      const tmpForeigner = {
        custNm: '', // 성명
        knbFrno: '', // 식별번호 앞자리
        knbBkno: '', // 식별번호 뒷자리
        idcdIsuDt: '', // 발급일자 (YYYY-MM-DD)
        knbBknoTxt: '', // 식별번호 뒷자리(masked)
        knbScCd: '', // ios encrypt key
        knbEncr: '', // 식별번호 (encrypt)
        seriaNo: '', // 일련번호 11자리
      }
      this.lv_Korean = Object.assign({}, tmpKorean)
      this.lv_Driver = Object.assign({}, tmpDriver)
      this.lv_Foreigner = Object.assign({}, tmpForeigner)    
      
      // 보안키패드 초기화
      this.lv_isKoClear = !this.lv_isKoClear ? true : false
      this.lv_isDrClear = !this.lv_isDrClear ? true : false
      this.lv_isFoClear = !this.lv_isFoClear ? true : false
    },    
    /******************************************************************************
    * Function명: fn_getMaskType
    * 설명: ios에서 넘어온 mtranskey key
    * Params: N/A
    * Return: N/A
    ******************************************************************************/
    fn_getMaskType (val) {
      const target = this[this.lv_SelectedSeg]
      target.knbScCd = val
    },
    fn_SetMaskedTxt (val) {
      const target = this[this.lv_SelectedSeg]
      target.knbBknoTxt = val
    },
    fnMakeParams (type, tlgmNo) {      
      let params = {}
      let trnstId = ''
      const target = this[this.lv_SelectedSeg]

      // this.fnRrnCheckRule(true)
      const {userId: auth} = this.getStore('userInfo').getters.getUserInfo // 컨설턴트 정보

      switch (this.lv_SelectedSeg) {
        // 주민등록증
        case 'lv_Korean':
          if (type === '1') {
            trnstId = 'txTSSBC01P1'
            params = {
              auth,
              cusNm: target.custNm,
              psNo: target.knbEncr,
              idcdIsuDt: moment(target.idcdIsuDt).format('YYYYMMDD')
            }
          } else if (type === '2') {
            trnstId = 'txTSSBC01P2'
            params = {
              auth,
              tlgmNo,
            }
          } else {
            trnstId = 'txTSSBC01P7'
            params = {
              auth,
              tlgmNo,
            }
          }
          break
        // 운전면허증
        case 'lv_Driver':
          if (type === '1') {
            trnstId = 'txTSSBC01P3'
            params = {
              auth,
              cusNm: target.custNm,
              psNo: target.knbEncr,
              lcnsNo: target.lcnsNo1.replace(/\D/g, '') + target.lcnsNo2.replace(/\D/g, '') + target.lcnsNo3.replace(/\D/g, '') + target.lcnsNo4.replace(/\D/g, '')
            }
          } else if (type === '2') {
            trnstId = 'txTSSBC01P4'
            params = {
              auth,
              tlgmNo,
            }
          } else {
            trnstId = 'txTSSBC01P7'
            params = {
              auth,
              tlgmNo,
            }
          }
          break
        // 외국인등록증
        case 'lv_Foreigner':
          if (type === '1') {
            trnstId = 'txTSSBC01P5'
            params = {
              auth,
              psNo: target.knbEncr,
              idcdIsuDt: moment(target.idcdIsuDt).format('YYYYMMDD'),
              serialnumber: target.seriaNo // 일련번호
            }
          } else if (type === '2') {
            trnstId = 'txTSSBC01P6'
            params = {
              auth,
              tlgmNo,
              serialnumber: target.seriaNo // 일련번호
            }
          } else {
            trnstId = 'txTSSBC01P7'
            params = {
              auth,
              tlgmNo,
            }
          }
          break
      }
      this.fnServiceCall(params, trnstId, 'S')
    },
    fnServiceCall (params, trnstId, auth) {
      this.post(this, params, trnstId, auth)
        .then((response) => {
          this.fnServiceCallHandler(response)
        })
        .catch((error) => {
          window.vue.error(error)
        })
    },
    fnServiceCallHandler (response) {
      const trnstId = response.trnstComm.trnstId
      const {tlgmNo, code, message} = response.body
      
      if (code === 'X' || code === 'E') {        
        this.$addSnackbar(code + ': ' + message)
        return
      }

      if (trnstId === 'txTSSBC01P1' || trnstId === 'txTSSBC01P3' || trnstId === 'txTSSBC01P5') {
        this.fnMakeParams('2', tlgmNo)
      } else if (trnstId === 'txTSSBC01P2' || trnstId === 'txTSSBC01P4' || trnstId === 'txTSSBC01P6') {
        this.fnMakeParams('3', tlgmNo)
      } else {        
        if (message === 'U149' && this.cntU149 < 20) {
          this.cntU149 = this.cntU149 + 1
          // this.fnMakeParams('2', tlgmNo)
          setTimeout(_ => this.fnMakeParams('2', tlgmNo), 500)
        } else {
          this.cntU149 = 0          
          if (code === 'Y') {                        
            this.modalMassAuth.open() // 성공 시 다중 확인 팝업
          } else {
            // 기타 예외
            this.$addSnackbar('진위여부 확인에 실패하였습니다.\n입력 정보를 다시 한번 확인해 주세요.')
          }
        }
      }
    },    
    /******************************************************************************
    * Function명 : fn_CheckSsnValidation
    * 설명       : 주민번호 유효성 체크
    ******************************************************************************/
    fn_CheckSsnValidation (e) {
      var watcher = null      
      switch (this.lv_SelectedSeg) {        
        case 'lv_Korean':
          watcher = this.$refs.vWatcherKorean
          break
        case 'lv_Driver':
          watcher = this.$refs.vWatcherDriver
          break
        case 'lv_Foreigner':
          watcher = this.$refs.vWatcherForeigner
          break
      }     

      const target = this[this.lv_SelectedSeg]
      watcher.validate()
      if (!watcher.isValid()) {
        this.$addSnackbar({text: watcher.validate(), error: !watcher.isValid()})
        switch (this.lv_SelectedSeg) {        
          case 'lv_Korean':
          case 'lv_Foreigner':
          case 'lv_Driver':
            if (target.custNm === '' || target.custNm.length <= 2) {
              if('lv_Korean' === this.lv_SelectedSeg){
                this.$refs['koName'].focus()
              } else if('lv_Foreigner' === this.lv_SelectedSeg){
                this.$refs['foName'].focus()
              } else if('lv_Driver' === this.lv_SelectedSeg){
                this.$refs['drName'].focus()
              }
              return
            }

            if (target.knbFrno === '' || target.knbFrno.length !== 6) {
              if('lv_Korean' === this.lv_SelectedSeg){
                this.$refs['koKnbFrno'].focus()
              } else if('lv_Foreigner' === this.lv_SelectedSeg){
                this.$refs['foKnbFrno'].focus()
              } else if('lv_Driver' === this.lv_SelectedSeg){
                this.$refs['drKnbFrno'].focus()
              }
              return
            }

            if (target.knbBrno === '' || target.knbBrno.length !== 7) {
              if('lv_Korean' === this.lv_SelectedSeg){
                this.$refs['koKnbBrno'].focus()
              } else if('lv_Foreigner' === this.lv_SelectedSeg){
                this.$refs['foKnbBrno'].focus()
              } else if('lv_Driver' === this.lv_SelectedSeg){
                this.$refs['drKnbBrno'].focus()
              }
              return
            }
            break
          case 'lv_Driver':            

            if (target.lcnsNo1 === '' || target.lcnsNo1.length !== 2) {
              this.refs['lcnsNo1'].focus()
              return
            }

            if (target.lcnsNo2 === '' || target.lcnsNo2.length !== 2) {
              this.refs['lcnsNo2'].focus()
              return
            }

            if (target.lcnsNo3 === '' || target.lcnsNo3.length !== 6) {
              this.refs['lcnsNo3'].focus()
              return
            }

            if (target.lcnsNo4 === '' || target.lcnsNo4.length !== 2) {
              this.refs['lcnsNo4'].focus()
              return
            }            
            break          
        }        
        
      }

      // PC일 경우 전체 평문 주민번호를 넘겨줌. 모바일일 경우 공백처리
      const knb = this.fn_IsMobile ? '' : (target.knbFrno + '' + target.knbBkno)

      const pParams = {
        knb, // 전체 주민번호
        knbFrno: target.knbFrno, // 주민번호 앞번호
        knbBknoCipher: target.knbBkno, // 주민번호 뒷번호
        knbScCd: target.knbScCd // ios encrypt key
      }

      const trnstId = 'txTSSCM10S6'
      this.post(this, pParams, trnstId, 'S')
        .then(response => {
          // 서버 데이터 가져오기
          if (response.body !== null) {
            if (response.body.ssnYn === 'Y') {
              target.knbEncr = response.body.knbEncr
              this.fnMakeParams('1')
            }
          } else {
            this.$addSnackbar(response.msgComm.msgDesc)
          }
        })
        .catch(function (error) {
          window.vue.error(error)
        })
    },
    /******************************************************************************
    * Function명 : fn_TabChangeHandler
    * 설명       : 탭 변경 시
    ******************************************************************************/
    fn_TabChangeHandler(idx) {
      switch(Number(idx)) {
        case 1: 
         this.lv_SelectedSeg = 'lv_Korean'
         break
        case 2: 
         this.lv_SelectedSeg = 'lv_Driver'
         break
        case 3: 
         this.lv_SelectedSeg = 'lv_Foreigner'
         break
      }
    },    
    fn_OpenModal() {      
      this.$refs.idFakeCheck.open()
      this.fn_Init()
    },    
    fn_CloseModal() {    
      this.$refs.authSucs.close()
      this.$refs.exit.close()
      this.$refs.massAuth.close()
      this.$refs.idFakeCheck.close()
    },
    /******************************************************************************
    * Function명 : fn_ExitModal
    * 설명       : 모달창 닫기
    ******************************************************************************/
    fn_ExitModal() {
      this.modalExit.open()
    },
    /******************************************************************************
    * Function명 : fn_IdFakeCheckSucs
    * 설명       : 진위여부 확인 성공 시 단일 확인 팝업
    * 사용안함여부 : 사용안함
    ******************************************************************************/
    fn_AuthSucs() {            
      if (this.popupObj.jobTyp === 'fs') {
        this.modalAuthSucs.close()
        this.fn_Complete() // 수익증권 시 다건 팝업 확인 없이 완료        
      } else if(this.popupObj.jobTyp === 'ts') {        
        this.modalMassAuth.open() // 신탁 일 경우만 다건 팝업 확인 
      }
    },    
    /******************************************************************************
    * Function명 : fn_IdFakeCheckSucs
    * 설명       : 진위여부 확인 성공 시 다중 확인 팝업
    ******************************************************************************/
    fn_IdFakeCheckSucs(isSkip = false) {      
      this.lv_IsSkip = isSkip
      this.modalMassAuth.open() // 성공 시 다중 확인 팝업
    },    
    /******************************************************************************
    * Function명 : fn_IdFakeCheckFail
    * 설명       : 진위여부 확인 실패 시
    ******************************************************************************/
    fn_IdFakeCheckFail() {
      let lv_Vm = this      
      lv_Vm.pAlertPopupObj.confirm = false
      lv_Vm.pAlertPopupObj.content = '진위여부 확인에 실패하였습니다.<br/>입력 정보를 다시 확번 확인해주세요'
      lv_Vm.$refs.alertPopup.fn_Open()
    },    
    /******************************************************************************
    * Function명 : fn_ExitConfirmYes
    * 설명       : 종료 시
    ******************************************************************************/
    fn_ExitConfirmYes() {         
      this.$emit('fs-popup-callback', 0, 'N') // N : 취소
      this.$addSnackbar('앱을 종료합니다.')
    },    
    /******************************************************************************
    * Function명 : fn_Complete
    * 설명       : 완료 시
    ******************************************************************************/
    fn_Complete() {      
      if(!this.lv_IsSkip){        
        this.$emit('fs-popup-callback', 0, 'Y') // Y : 성공
      }else {
        let jobTyp = this.$route.name.substr(3,2).toLowerCase()
        if(jobTyp === 'fs') {
          this.$bizUtil.fsUtils.saveDocData(this)
        } else if(jobTyp === 'ts'){
          this.$bizUtil.tsUtils.saveDocData(this)
        }
        this.$emit('fs-popup-callback', 0, 'S') // S : 고객서류 등록 스킵 시
      }
    },
    /******************************************************************************
    * Function명 : fn_MassAuthConfirmYes
    * 설명       : 다건인증 팝업 확인
    ******************************************************************************/
    fn_MassAuth(type) {
      this.modalMassAuth.close()
      if (type === 'Y') {        
        this.fn_Init()
        console.log('fn_MassAuthConfirmYes >> ')        
      } else {        
        this.fn_Complete()
        console.log('fn_MassAuthConfirmCancle >> ')
      }     
    },
    /******************************************************************************
    * Function명 : fn_NextFocus
    * 설명       : 주민등록번호 앞자리 입력 완료시 다음 input으로 focus 이동
    ******************************************************************************/
    fn_NextFocus (event) {
      if(event.target.value.length === 6) {
        switch (this.lv_SelectedSeg) {
          case 'lv_Korean':
            this.$refs['koKnbBrno'].focus();
            break
          case 'lv_Driver':
            this.$refs['drKnbBrno'].focus();
            break
          case 'lv_Foreigner':
            this.$refs['foKnbBrno'].focus();
            break
        }
      }
    },

    /******************************************************************************
    * Function명 : fn_NextFocusLcnsNo
    * 설명       : 운전면허번호 입력 완료시 다음 input으로 focus 이동
    ******************************************************************************/
    fn_NextFocusLcnsNo (lcnsno) {
      if(lcnsno === 1 && this.lv_Driver.lcnsNo1.length === 2) {
        this.$refs['lcnsNo2'].focus();
      }
      if(lcnsno === 2 && this.lv_Driver.lcnsNo2.length === 2) {
        this.$refs['lcnsNo3'].focus();
      }
      if(lcnsno === 3 && this.lv_Driver.lcnsNo3.length === 6) {
        this.$refs['lcnsNo4'].focus();
      }
    },

    openModal(type) {
      switch (type) {
        case 1:
          this.modal1.open();
          break;
        default:
          this["modal"+type].open();
          break
      }
    },
    closeModal(type) {
      switch (type) {
        case 1:
          this.modalMassAuth.close()
          this.modal1.close();                 
          break;
        default:
          this["modal"+type].close();
          break
      }
    },    
  }
};
</script>
<style scoped>
</style>